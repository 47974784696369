async function usePlayerCleanup(store, abortController) {
  console.info("[🫧 playerCleanup]", abortController);
  abortController.abort();
  console.info("[🫧 playerCleanup] aborted", store.abortSignal?.aborted);
  if (document.pictureInPictureElement) {
    await document.exitPictureInPicture();
  }
  console.info("[🫧 playerCleanup] exited PiP");
}

export default usePlayerCleanup;
